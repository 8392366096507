import * as _ from 'lodash'
import { recommendedFields } from './recommended-fields'
import { generalFields } from './general-fields'
import { registrationFormFields } from './registration-form-fields'
import { FieldCollectionType } from '../../../../constants/wixcode'
import { COMPONENT_TYPES } from '../../../../constants/component-types'
import { FieldPreset, customTypes } from '../../../../constants/field-types'
import { iconNames } from './icons/types'
import { FieldRenderConfigType } from './constants'
import { FormPlugin } from '../../../../constants/plugins'
import { adiFormFields } from './adi-form-fields'
import { LayoutResponsive } from '../../../../constants/responsive-types'

export interface FieldExtraData {
  role: string
  connectionConfig?: {
    crmType: string
    crmLabel?: string
    crmTag?: string
    customFieldId?: string
    label?: string
  }
  props?: any
  data?: any
  layout?: any
  layoutResponsive?: LayoutResponsive
}

export interface FieldProperties {
  collectionFieldType?: FieldCollectionType | false
  componentType: COMPONENT_TYPES
  extraData: FieldExtraData
}

export interface FieldRenderConfigStructure {
  isMandatory?: boolean
  crmSync?: FieldRenderConfigType
  required?: FieldRenderConfigType
  internalName?: FieldRenderConfigType
  checkedByDefault?: FieldRenderConfigType
  isEditable?: boolean
}

export type FieldRenderConfig = { [key in FormPlugin]?: FieldRenderConfigStructure }

// TODO: Merge this with default form builder config render and merge unused flows (AKA === required.ENABLED/REMOVE? why both?)
const DEFAULT_FIELD_RENDER_CONFIG: FieldRenderConfigStructure = {
  isEditable: true,
  isMandatory: false,
  crmSync: FieldRenderConfigType.ENABLED,
  required: FieldRenderConfigType.ENABLED,
  internalName: FieldRenderConfigType.ENABLED,
  checkedByDefault: FieldRenderConfigType.ENABLED,
}

export type PluginsFieldExtraData = { [key in FormPlugin]?: Partial<FieldExtraData> }

export interface FieldData {
  make: () => FieldProperties
  customFields: customTypes[]
  icon: iconNames
  renderConfig?: FieldRenderConfig
  titleTranslationKey?: string
  allowOnlyOnce?: boolean
  disableCollectionSync?: boolean
  pluginsExtraData?: PluginsFieldExtraData
}

const getFieldData = (fieldType: FieldPreset): FieldData => {
  return getAllFieldsData()[fieldType]
}

export const getAllFieldsData = () => ({
  ...recommendedFields(),
  ...generalFields(),
  ...registrationFormFields(),
  ...adiFormFields(),
})

export const getFieldProperties = (fieldType: FieldPreset): FieldProperties => {
  return getFieldData(fieldType).make()
}

export const getFieldCustomFields = (fieldType: FieldPreset): customTypes[] => {
  return getFieldData(fieldType).customFields
}

export const getFieldRenderConfigFields = (
  plugins: FormPlugin[],
  fieldType: FieldPreset,
  defaults = {}
): FieldRenderConfigStructure => {
  const config = getFieldData(fieldType).renderConfig
  const plugin = _.first(plugins) // current implementation support only 1 plugin per preset, change this when we will have more than 1
  let basePluginConfig = {}

  if (config && config[FormPlugin.FORM_BUILDER]) {
    basePluginConfig = config[FormPlugin.FORM_BUILDER]
  }

  if (config && plugin && config[plugin]) {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, config[plugin])
  } else {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, defaults)
  }
}

export const getFieldPluginsExtraData = ({
  fieldType,
  plugins,
}: {
  fieldType: FieldPreset
  plugins: FormPlugin[]
}): Partial<FieldExtraData> => {
  const pluginsExtraData = getFieldData(fieldType).pluginsExtraData

  let extraData = {}

  if (!pluginsExtraData) {
    return extraData
  }

  _.forEach(plugins, plugin => {
    const pluginExtraData = _.get(pluginsExtraData, plugin)

    if (pluginExtraData) {
      _.merge(extraData, pluginExtraData)
    }
  })

  return extraData
}

export const getFieldIconName = (fieldType: FieldPreset): iconNames => {
  return getFieldData(fieldType).icon
}

export const getFieldTitleTranslationKey = (fieldType: FieldPreset): string => {
  return getFieldData(fieldType).titleTranslationKey
}

export const isFieldAllowedOnlyOnce = (fieldType: FieldPreset): boolean => {
  return !!getFieldData(fieldType).allowOnlyOnce
}

export const allowCollectionSync = (fieldType: FieldPreset): boolean => {
  return !!!getFieldData(fieldType).disableCollectionSync
}
