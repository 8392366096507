import { createPanelDefs } from '../core/services/panel-definitions'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import { EVENTS, ORIGINS, PANEL_NAMES, BUILDER_ORIGINS } from '../../constants/bi'
import * as Raven from 'raven-js'
import { GFPP, GFPP_IDS } from '../core/manifests/manifest-commons'
import { getApi } from '../editor-ready/editor-ready'
import { openComponentPanel, openManageSubscribersPanel, openHelpPanel, openDynamicFieldSettingsPanel } from '../core-actions'
import { getBiLogger, getSiteId, setBiLogger, setPanelDefinitions } from '../editor-app-impl'
import { PanelName } from '../core/manage-panels/consts/panel-names'
import { getPrimaryConnection } from '../core/utils'
import { FormsFieldPreset } from '../../constants/field-types'
import * as _ from 'lodash'
import CoreApi from '../core/core-api'

const handleGfppClickedImpl = async ({
  componentRef,
  id,
  isConnectPanel,
  api,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
  isConnectPanel: boolean
  api: CoreApi
}) => {
  const biLogger = getBiLogger()
  const onFieldSettingsOpenEvent = evid => ({fieldType, crmLabel, formId}) =>
    biLogger.log({
      evid,
      field_type: fieldType,
      field_name: crmLabel,
      origin: ORIGINS.GFPP,
      form_comp_id: formId,
    })

  switch (id) {
    case GFPP_IDS.FORM_SETTINGS:
      openComponentPanel(componentRef, PanelName.FORM_SETTINGS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.NEW_FORM_SETTINGS:
      openComponentPanel(componentRef, PanelName.NEW_FORM_SETTINGS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.settingsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.MANAGE_FIELDS:
      openComponentPanel(componentRef, PanelName.FORM_MANAGE_FIELDS, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.manageFieldsPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.ADD_FIELD:
      openComponentPanel(componentRef, PanelName.ADD_FIELD, ({ preset, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL,
          template: preset,
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.MANAGE_SUBSCRIBERS:
      openManageSubscribersPanel()
      break
    case GFPP_IDS.FIELD_SETTINGS:
      const evid = isConnectPanel
        ? EVENTS.PANELS.connectFieldPanel.OPEN_PANEL
        : EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL
      openComponentPanel(
        componentRef,
        PanelName.FIELD_SETTINGS,
        onFieldSettingsOpenEvent(evid),
      )
      break
    case GFPP_IDS.DYNAMIC_FIELD_SETTINGS:
      openDynamicFieldSettingsPanel(componentRef, onFieldSettingsOpenEvent(EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL))
      break
    case GFPP_IDS.FORM_STYLE:
      openComponentPanel(componentRef, PanelName.FORM_STYLE)
      break
    case GFPP_IDS.FORM_LAYOUT:
      openComponentPanel(componentRef, PanelName.FORM_LAYOUT, ({ columns, formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.formLayoutPanel.OPEN_PANEL,
          action: 'open',
          layout: columns === 1 ? 'single' : columns ? `${columns} column` : '2 column',
          form_comp_id: formId,
        })
      )
      break
    case GFPP_IDS.SUBMIT_SETTINGS:
      openComponentPanel(componentRef, PanelName.SUBMIT_SETTINGS)
      break
    case GFPP_IDS.RECAPTCHA_HELP:
      openHelpPanel(GFPP.HELP_ID.CAPTCHA, () =>
        biLogger.log({
          evid: EVENTS.PANELS.recaptchaHelp.OPEN_PANEL,
          field_group: 'custom',
          field_name: FormsFieldPreset.GENERAL_RECAPTCHA,
          field_type: 'Captcha',
        })
      )
      break
    case GFPP_IDS.MANAGE_STEPS:
      openComponentPanel(componentRef, PanelName.MANAGE_STEPS, ({ formId }) =>
        biLogger.log({
          evid: EVENTS.PANELS.manageSteps.OPEN_PANEL,
          click_type: 'manage steps button',
          form_comp_id: formId,
          builderOrigin: BUILDER_ORIGINS.EDITOR,
        })
      )
      break
    case GFPP_IDS.CHANGE_BUTTON_LABEL:
      const componentConnection = await api.getComponentConnection(componentRef)
      const role = _.get(componentConnection, 'role')

      openComponentPanel(componentRef, PanelName.CHANGE_BUTTON_LABEL, _.noop, { role })
      break
    default:
      break
  }
}

export const handleAppWidgetGfppClicked = async ({
  componentRef,
  id,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
}) => {
  const api = await getApi()
  const formContainer = await api.getFormContainerOfAppWidget(componentRef)
  const isConnectPanel = api.isConnectPanel()
  return handleGfppClickedImpl({ componentRef: formContainer, id, isConnectPanel, api })
}

export const handleGfppClicked = async ({
  componentRef,
  id,
}: {
  componentRef: ComponentRef
  id: GFPP_IDS
}) => {
  const api = await getApi()
  const isConnectPanel = api.isConnectPanel()
  return handleGfppClickedImpl({ componentRef, id, isConnectPanel, api })
}

export const handleComponentDelete = async ({ componentRef, connections, historySnapshotId }) => {
  const api = await getApi()
  const connection = getPrimaryConnection(connections)
  api.handleDelete(componentRef, connection, historySnapshotId)
}

export const handlePublish = async () => {
  const api = await getApi()
  api.interactionStarted('publish-site')
  await api.sendAllFormsData()
  api.interactionEnded('publish-site')
}

export const handleFirstSave = async ({ metaSiteId: newMsid }) => {
  const api = await getApi()
  if (newMsid) {
    Raven.setUserContext({ id: `msid_${newMsid}` })
    const panelDefinitions = createPanelDefs(newMsid)
    const biLogger = await initBiLoggerForEditorApp(newMsid)
    setBiLogger(biLogger)
    setPanelDefinitions(panelDefinitions)
    api.setBiLogger(biLogger)
  }
  const siteId = await getSiteId()
  await api.reportBiFirstSave(siteId)

  if (api.shouldCreateMissingFormLabels()) {
    const allFormsRefsAndConnections = await api.getAllFormsRefsAndConfigs()
    await api.createMissingFormLabels(allFormsRefsAndConnections)
  }
}

export const handlePresetChanged = async ({ componentRef, preset }) => {
  const api = await getApi()
  const esi = await api.getEditorSessionId()
  const biData = {
    startBi: {
      form_comp_id: componentRef.id,
      panel_name: PANEL_NAMES.formStylePanel,
      control_name: 'main',
      value: preset,
      esi,
    },
  }
  api.style.updateTheme(componentRef, preset, biData)
}

export const handleAppWidgetPasted = async ({ componentRef: controllerRef }) => {
  const api = await getApi()
  if (
    api.shouldEnforceFormsNumber() &&
    (await api.addForm.hasExceededFormsCount({ hasNewFormRef: !!controllerRef }))
  ) {
    api.addForm.preventFormAddition(controllerRef)
    return
  }

  const formRef: ComponentRef = await api.getFormContainerOfAppWidget(controllerRef)
  if (await api.isMultiStepForm(formRef)) {
    await Promise.all([
      api.steps.updateMultiStepFormTitles(formRef),
      api.steps.updateConnectionConfigStepsOrder(formRef),
    ])
  }
  await api.reportBiAppWidgetPasted(formRef)
  await api.selectComponent(controllerRef)
}

export const handleConnectedComponentPasted = async ({ componentRef }) => {
  const api = await getApi()
  await api.fields.onDuplicateField(componentRef)
}

export const handleStateChanged = async ({ componentRef }) => {
  const api = await getApi()
  await api.steps.onStateChanged({ componentRef })
}
