import * as _ from 'lodash'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { PROPERTIES, FormField } from '../../../constants/api-types'
import { getComponentTypeLayoutProp } from '../services/layout-service'
import {
  LayoutResponsive,
  StackItemLayout,
  LayoutDataItem,
} from '../../../constants/responsive-types'
import { FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD } from '../../../constants/roles'

export type FieldLayoutProp = {
  [key: string]: PROPERTIES
} | null

export type FieldPropHandler = (
  componentType: COMPONENT_TYPES,
  value: PROPERTIES
) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const getTextAlignmentProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'textAlignment', value)
}

export const getLabelMarginProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'labelMargin', value)
}

export const getLabelPaddingProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'labelPadding', value)
}

export const getInputTextPaddingProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'textPadding', value)
}

export const findNewFieldStackLayout = (fieldsData: FormField[]): LayoutResponsive => {
  const fieldsDefaultLayouts: LayoutDataItem[] = fieldsData
    .filter(fieldData => _.includes(FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD, fieldData.role))
    .map(fieldData => fieldData.layoutResponsive.itemLayouts[0])

  const lastFieldLayout: StackItemLayout = _.maxBy(
    fieldsDefaultLayouts as StackItemLayout[],
    'order'
  )

  const order: number = _.get(lastFieldLayout, 'order', 0) + 1

  return {
    type: 'LayoutData',
    componentLayouts: [
      {
        type: 'ComponentLayout',
        width: {
          type: 'percentage',
          value: 90,
        },
        hidden: false,
        breakpoint: '',
      },
    ],
    containerLayouts: [],
    itemLayouts: [
      {
        type: 'StackItemLayout',
        alignSelf: 'start',
        order,
        margins: {
          left: {
            type: 'px',
            value: 20,
          },
          top: {
            type: 'px',
            value: 20,
          },
        },
        justifySelf: 'end',
        breakpoint: '',
      },
    ],
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
  }
}

export const calcUpdatesForStackFieldsByOrder = (
  fieldsData: FormField[],
  newFieldOrder: number
): { componentRef: ComponentRef; responsiveLayout: LayoutResponsive }[] => {
  const fieldsNeededForUpdate = fieldsData.filter(
    (fieldData: FormField) =>
      (fieldData.layoutResponsive.itemLayouts[0] as StackItemLayout).order >= newFieldOrder
  )
  return fieldsNeededForUpdate.map(fieldData => {
    const defaultStackItemLayout = <StackItemLayout>fieldData.layoutResponsive.itemLayouts[0]
    return {
      componentRef: fieldData.componentRef,
      responsiveLayout: {
        ...fieldData.layoutResponsive,
        itemLayouts: [{ ...defaultStackItemLayout, order: defaultStackItemLayout.order + 1 }],
      },
    }
  })
}
